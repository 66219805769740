<template>
  <div class="wrapper">
    <h4>服务回访</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="ID" prop="id">
            <el-input v-model="form.id" placeholder="请输入ID" auto-complete="off" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="描述" prop="revisitDesc">
            <el-input v-model="form.revisitDesc" placeholder="请输入描述" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="评分" prop="revisitStart">
            <el-rate v-model="form.revisitStart" show-text :texts="text"></el-rate>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="照片" prop="imgs">
            <upload-img :limit="5" :uploadLists="imgList" @uploadChange="uploadSuccess"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" v-if="showSave" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from 'axios'

export default {
  name: 'AddRevisit',
  components: {
    UploadImg,
  },
  data() {
    return {
      imgList: [],
      form: {},
      text:['特差','差','合格','良好','优秀'],
      rules: {
        // id: [{ required: true, message: '请输入开卡ID', trigger: 'blur' }],
        // personId: [{ required: true, message: '输入用户ID', trigger: 'blur' }],
        // amount: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        // description: [{ required: true, message: '请输入描述', trigger: 'blur' }],
      },
      showSave: true
    }
  },
  computed: {
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function (item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          //然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addRevisit']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.id = this.$route.query.id
      this.revisitStart = this.$route.query.revisitStart
      if (this.revisitStart !== null) {
        this.showSave = false
      }
      let revisitImgInfoList = this.$route.query.revisitImgInfoList
      const revisitDesc = this.$route.query.revisitDesc
      const revisitImgs = []
      const imgList = []
      console.log(revisitImgInfoList !== '')
      if (revisitImgInfoList !== 'null') {
        revisitImgInfoList = JSON.parse(decodeURIComponent(revisitImgInfoList))
        revisitImgInfoList.forEach(item => {
          revisitImgs.push(item.id)
          imgList.push({
            id: item.id,
            url: item.url
          })
        })
      }
      const that = this
      setTimeout(() => {
        that.imgList = imgList
      })
      console.log(typeof this.imgList)
      this.form = {
        id: this.id,
        revisitStart: this.revisitStart,
        revisitImgs: revisitImgs.join(','),
        revisitDesc: revisitDesc
      }
    },
    uploadSuccess(data) {
      this.form.imgs = data.join(',')
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'addRevisit'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '回访成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
 .el-rate {
    line-height: 2.6;
  }
</style>
